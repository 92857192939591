




































import VSelectWithValidation from '@/components/VSelectWithValidation.vue';
import VTextFieldWithValidation from '@/components/VTextFieldWithValidation.vue';
import { IRateLimit, IRateLimitCreate } from '@/interfaces';
import { dispatchCreateRateLimit } from '@/store/main/accessors';
import { extend, ValidationProvider } from 'vee-validate';
import { Component, Vue } from 'vue-property-decorator';
import { min_value } from 'vee-validate/dist/rules';

extend('min_value', min_value);

@Component({
  components: {
    VTextFieldWithValidation,
    VSelectWithValidation,
  },
})
export default class SendMessage extends Vue {
  public periods = [
    { name: this.$vuetify.lang.t('$vuetify.oneMinute'), duration: 60 },
    { name: this.$vuetify.lang.t('$vuetify.oneHour'), duration: 60 * 60 },
    { name: this.$vuetify.lang.t('$vuetify.oneDay'), duration: 60 * 60 * 24 },
  ];
  public periodItems = this.periods.map((p) => p.name);
  public periodName = this.periods[0].name;
  public limit = 0;
  public commentary = '';

  public isValid = false;
  public $refs!: {
    observer: InstanceType<typeof ValidationProvider>;
  };

  public async reset() {
    this.limit = 0;
    this.commentary = '';
    this.$refs.observer.reset();
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$refs.observer.validate()) {
      const { name, duration } = this.periods.find((p) => p.name === this.periodName)!;
      const rateLimit: IRateLimitCreate = {
        name,
        limit: this.limit,
        duration,
        commentary: this.commentary,
      };
      const result: IRateLimit = await dispatchCreateRateLimit(this.$store, rateLimit);

      if (result?.id) {
        this.$router.push({ name: 'main-rate-limits-all' });
      }
    }
  }
}
